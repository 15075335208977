@use "sass:map";
@import "../../assets/theme.scss";

.truckButton {
  position: relative;
  display: inline-block;
  margin-top: 1.5rem;
  padding: 0.75rem 2rem;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 0.3rem;
  text-decoration: none;
  color: map.get($theme, "antiflashWhite");
  background: map.get($theme, "redGradient");
  transition: all 0.3s ease-in-out;
  z-index: 1;

  /* Pseudo-element symulujący gradientową obramówkę */
  &:before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: map.get($theme, "redGradient");
    border-radius: 0.4rem;
    z-index: -1;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    background: map.get($theme, "yinmRedAfter");
    color: map.get($theme, "antiflashWhite");
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    padding: 0.6rem 1.5rem;
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    padding: 0.5rem 1rem;
    font-size: 0.85rem;
    margin-top: 1rem;
  }
}
