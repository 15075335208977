@use "sass:map";
@import "./assets/theme.scss";
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-size: 18px;
  letter-spacing: 0.5px;
  box-sizing: border-box;
  color: map.get($theme, "richBlack");
  font-family: "Red Hat Display", sans-serif;
}

body {
  background-color: map.get($theme, "antiflashWhite");
}
