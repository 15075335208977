@use "sass:map";
@import "../../../../assets/theme.scss";

.wrapper {
  width: 100%;
  z-index: 997;
}

.bar {
  width: 100%;
  padding: 1.5rem 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 999;
  background-color: map.get($theme, "antiflashWhite");
  box-shadow: 1px 1px 3px 0px rgba(map.get($theme, "richBlack"), 0.2);

  @media screen and (max-width: 1024px) {
    padding: 1rem;
  }
}

.logo {
  width: 120px;
  height: auto;
}

.spacer {
  flex: 1;
}

.links {
  display: flex;
  justify-content: center;
  align-items: center;

  & > li {
    margin: 0 1.5rem;
    display: inline-block;

    & > a {
      width: 100%;
      height: 100%;
      color: map.get($theme, "richBlack");
      padding: 0.3rem 0.3rem;
      text-decoration: none;
      font-size: 1rem;
      font-weight: 500;
    }
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.burgerMenu {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  cursor: pointer;

  & > span {
    height: 3px;
    width: 100%;
    display: block;
    border-radius: 0.04rem;
    background-color: map.get($theme, "yinmnRed");
    transition: all 0.3s linear;
  }

  @media screen and (min-width: 1024px) {
    display: none;
  }
}

.burgerMenuActive {
  & > span {
    &:first-of-type {
      opacity: 1;
      transform: rotateZ(45deg) translateY(15px);
    }

    opacity: 0;

    &:last-of-type {
      opacity: 1;
      transform: rotateZ(-45deg) translateY(-15px);
    }
  }
}

.drawer {
  top: 0;
  right: 0;
  width: 80dvw;
  position: fixed;
  height: 100dvh;
  z-index: 998;
  background-color: map.get($theme, "antiflashWhite");
  box-shadow: 1px 1px 3px 2px rgba(map.get($theme, "richBlack"), 0.2);
  padding-top: calc(2rem + 40px + 1.2rem + 1rem);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobileOverlay {
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  position: fixed;
  z-index: 996;
  background-color: #000;
}

.mobileLink {
  display: block;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 0.5rem;
}

.mobileLinkActive {
  background: map.get($theme, "redGradient");
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.socialWrapper {
  display: flex;
  margin-bottom: 1rem;
}

.socialLink {
  position: relative;
  height: fit-content;

  & * {
    font-size: 1.9rem;
    margin-right: 0.5rem;
  }

  & svg {
    fill: grey;
    transition: all 0.4s ease-in-out;
    position: relative;
    z-index: 2;
  }
}
