@use "sass:map";
@import "../../assets/theme.scss";

.wrapper {
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > h1 {
    font-size: 4rem;
    background: map.get($theme, "redGradient");
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  & > p {
    width: 45%;
    font-size: 1.1rem;
    text-align: center;
    color: black;

    @media screen and (max-width: 1024px) {
      width: 95%;
    }
  }

  & > p > a {
    font-weight: bolder;
    color: map.get($theme, yinmnRed);
  }

  & > a {
    margin-top: 1rem;
    cursor: pointer;
    padding: 0.5rem 2rem;
    text-transform: uppercase;
    letter-spacing: 0.03rem;
    font-weight: 600;
    border-radius: 0.2rem;
    text-decoration: none;
    color: map.get($theme, "antiflashWhite");
    border: 2px solid map.get($theme, "yinmnRed");
    background-color: map.get($theme, "yinmnRed");
    transition: all 0.4s ease-in-out;

    &:hover {
      color: map.get($theme, "yinmnRed");
      background-color: map.get($theme, "antiflashWhite");
    }
  }
}
