@use "sass:map";
@import "../../assets/theme.scss";

@mixin center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.parallaxContainer {
  background-image: url("../../assets/img/istock2.jpg");
  height: 450px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.textSection,
.formContainer {
  flex: 1;
  margin-right: 20px;
  padding: 20px;
  background-color: map.get($theme, "whiteOnly");
  border-radius: 8px;
  box-shadow: map.get($theme, "shadow");
  margin-bottom: 40px;

  h2,
  h3 {
    color: map.get($theme, "yinmnRed");
  }

  h2 {
    font-size: 2.2rem;
    margin-bottom: 15px;
  }

  h3 {
    font-size: 1.8rem;
    margin-top: 20px;
  }

  p,
  li {
    font-size: 1rem;
    color: map.get($theme, "richBlack");
    line-height: 1.5;
  }

  ul.benefitsList {
    list-style-type: none;
    padding-left: 0;
    li {
      margin-bottom: 10px;
    }
  }
}

.iconCircle {
  @include center-flex;
  width: 50px;
  height: 50px;
  border: 2px solid map.get($theme, "yinmnRed");
  border-radius: 50%;
  color: map.get($theme, "yinmnRed");
  margin-right: 15px;
}

.iconRow {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .text {
    font-size: 18px;
    color: map.get($theme, "yinmnRed");
  }
}

.container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;

  // Ensure both sections take equal space
  .infoSection,
  .formContainer {
    flex: 1;
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    .infoSection,
    .formContainer {
      margin-right: 0;
    }
  }
}

.infoSection {
  flex: 1;
  margin-right: 20px;

  h2,
  h3 {
    color: map.get($theme, "yinmnRed");
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 15px;
    margin-top: 50px;
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    margin-top: 50px;
  }

  p {
    font-size: 1rem;
    color: map.get($theme, "richBlack");
  }

  ul {
    list-style-type: none;
    padding: 0;
    li {
      font-size: 18px;
      font-weight: bold;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 15px;
      svg {
        color: map.get($theme, "yinmnRed");
      }
    }
  }
}

.form {
  display: flex;
  flex-direction: column;

  .inputGroup {
    margin-bottom: 20px;
    label {
      font-weight: bold;
      margin-bottom: 5px;
      color: map.get($theme, "richBlack");
    }

    input,
    textarea {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 16px;
      background-color: map.get($theme, "antiflashWhite");
      color: map.get($theme, "richBlack");
      resize: none;
    }

    textarea {
      resize: vertical;
      min-height: 100px;
    }
  }
}

button {
  margin-top: 1rem;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 0.2rem;
  color: map.get($theme, "antiflashWhite");
  background: map.get($theme, "redGradient");
  border: 2px solid map.get($theme, "yinmnRed");
  transition: all 0.4s ease-in-out;

  &:hover {
    color: map.get($theme, "yinmnRed");
    background: transparent;
  }
}

.error {
  color: map.get($theme, "yinmnRed");
  font-size: 12px;
  margin-top: 5px;
}

.mapContainer {
  @include center-flex;
  width: 100%;
  height: 500px;
  position: relative;
  background-color: map.get($theme, "antiflashWhite");

  @media (max-width: 768px) {
    height: 300px;
  }

  .mapIframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}

.ownersSection {
  text-align: center;
  padding: 50px 20px;
  background-color: map.get($theme, "antiflashWhite");

  .sectionHeading {
    font-size: 2rem;
    color: map.get($theme, "yinmnRed");
    font-weight: bold;
    margin-bottom: 0;
  }

  .underline {
    width: 60px;
    height: 4px;
    background-color: map.get($theme, "richBlack");
    margin: 10px auto 30px auto;
  }

  .description {
    font-size: 1.25rem;
    margin-bottom: 20px;
    color: map.get($theme, "richBlack");
    max-width: 1000px;
    margin: 0 auto;
  }

  .details,
  .closing {
    font-size: 1.25rem;
    line-height: 1.6;
    color: map.get($theme, "richBlack");
    max-width: 1000px;
    margin: 0 auto 20px auto;
  }

  .subheading {
    font-size: 1.75rem;
    color: map.get($theme, "yinmnRed");
    margin-top: 40px;
  }

  .benefitsList {
    list-style-type: none;
    padding: 0;
    max-width: 900px;
    margin: 0 auto 30px auto;

    li {
      font-size: 1.1rem;
      margin-bottom: 10px;
      color: map.get($theme, "richBlack");
      strong {
        color: map.get($theme, "yinmnRed");
      }
    }
  }

  .closing {
    font-size: 1.25rem;
    font-weight: bold;
  }
}

.formContainer {
  flex: 1;
  padding: 20px;
  background-color: map.get($theme, "whiteOnly");
  border-radius: 8px;
  box-shadow: map.get($theme, "shadow");
  margin-top: 50px;
  margin-left: auto;

  @media (max-width: 768px) {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .ownersSection {
    padding: 20px;
  }

  .container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .formContainer,
  .infoSection {
    width: 100%;
    margin: 0;
    padding: 20px;
  }

  .iconCircle {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .iconRow {
    margin-bottom: 15px;
    flex-direction: column;
    text-align: center;
  }

  .iconRow .text {
    font-size: 16px;
    text-align: center;
  }

  .ownersSection {
    text-align: center;
    padding: 20px 10px;
  }

  .iconCircle {
    width: 40px;
    height: 40px;
  }

  ul.benefitsList {
    padding-left: 0;
    list-style: none;
    margin: 0;

    li {
      margin-bottom: 10px;
      font-size: 1.1rem;
    }
  }
}

.iconCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border: 2px solid map.get($theme, "yinmnRed");
  border-radius: 50%;
  color: map.get($theme, "yinmnRed");
  font-size: 24px;
  margin-right: 15px;
  flex-shrink: 0;
}
