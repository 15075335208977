@use "sass:map";
@import "../../../../assets/theme.scss";

.footer {
  background-color: map.get($theme, "richBlack");
  padding: 3rem 2rem;
  color: map.get($theme, "antiflashWhite");

  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .contactInfo {
    text-align: left;

    h2 {
      color: map.get($theme, "antiflashWhite");
      font-weight: bold;
      margin-bottom: 1.5rem;
      text-transform: uppercase;
      font-size: 1.4rem;
    }

    .infoItem {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
      justify-content: flex-start;

      svg {
        color: map.get($theme, "yinmnRed");
        background-color: map.get($theme, "antiflashWhite");
        border-radius: 50%;
        padding: 0.6rem;
        width: 35px;
        height: 35px;
      }

      span {
        color: map.get($theme, "antiflashWhite");
        font-size: 1.1rem;
      }

      a {
        color: map.get($theme, "antiflashWhite");
        text-decoration: none;
        font-size: 1.1rem;
      }
    }

    @media (max-width: 768px) {
      text-align: center;
      .infoItem {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .rightSection {
    text-align: right;

    .about {
      margin-bottom: 2rem;
      max-width: 400px;
      color: map.get($theme, "antiflashWhite");
      font-style: italic;
      font-size: 1.2rem;
      line-height: 1.5;

      @media (max-width: 768px) {
        text-align: center;
        max-width: 100%;
      }
    }

    .socialMedia {
      display: flex;
      justify-content: flex-end;
      gap: 1.5rem;

      @media (max-width: 768px) {
        justify-content: center; // Wyśrodkowanie ikon social media
        gap: 1rem; // Zmniejszenie odstępów między ikonami
      }

      a {
        background-color: map.get($theme, "yinmnRed");
        padding: 0.7rem 1.5rem;
        border-radius: 5px;
        transition: background-color 0.3s ease;
        font-weight: bold;
        font-size: 1.1rem;
        text-decoration: none;
        color: map.get($theme, "antiflashWhite");
        border: 2px solid map.get($theme, "yinmnRed");
        background: map.get($theme, "redGradient");
        transition: all 0.4s ease-in-out;

        &:hover {
          color: map.get($theme, "antiflashWhite");
          background: transparent;
        }

        &:hover {
          background-color: map.get($theme, "dodgerBlue");
        }
      }
    }
  }

  .copyright {
    margin-top: 3rem;
    font-size: 1rem;
    text-align: center;
    width: 100%;
    color: map.get($theme, "antiflashWhite");

    @media (max-width: 768px) {
      font-size: 0.9rem; // Zmniejszenie rozmiaru czcionki dla mniejszych ekranów
    }
  }
}
