@use "sass:map";
@import "../../assets/theme.scss";

.callToAction {
  text-align: center;
  padding: 3rem 2rem;
  background-color: map.get($theme, "richBlack");
  color: map.get($theme, "whiteOnly");

  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: map.get($theme, "antiflashWhite");
  }

  p {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    color: map.get($theme, "antiflashWhite");
  }

  .ctaButton {
    margin-top: 1rem;
    padding: 0.5rem 1.5rem;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 0.2rem;
    text-decoration: none;
    color: map.get($theme, "antiflashWhite");
    border: 2px solid map.get($theme, "yinmnRed");
    background: map.get($theme, "redGradient");
    transition: all 0.4s ease-in-out;

    &:hover {
      color: map.get($theme, "antiflashWhite");
      background: transparent;
    }
  }
}
