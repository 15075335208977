@use "sass:map";
@import "../../assets/theme.scss";

.parallaxContainerFirst {
  background-image: url("../../assets/img/pexels1.jpg");
  height: 450px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.parallaxContainer {
  display: flex;
  align-items: center;
  height: 100vh;
  overflow: auto;

  .parallaxImage {
    background-image: url("../../assets/img/first.png");
    height: 100vh;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 1;
  }

  .content {
    flex: 1;
    padding: 4rem;
    color: map.get($theme, "antiflashWhite");
    text-align: left;
    background-color: map.get($theme, "redGradient");
    backdrop-filter: blur(1px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      font-size: 3rem;
      margin-bottom: 1.5rem;
      color: map.get($theme, "yinmnRed");
      position: relative;

      &::after {
        content: "";
        display: block;
        width: 50px;
        height: 4px;
        background-color: map.get($theme, "yinmnRed");
        margin-top: 0.5rem;
      }
    }

    h2 {
      font-size: 2rem;
      margin-bottom: 1rem;
      color: map.get($theme, "yinmnRed");
      position: relative;

      &::after {
        content: "";
        display: block;
        width: 50px;
        height: 4px;
        background-color: map.get($theme, "yinmnRed");
        margin-top: 0.5rem;
      }
    }

    p {
      font-size: 1.25rem;
      margin-bottom: 2rem;
      line-height: 1.6;
      color: map.get($theme, "richBlack");
    }
  }
}

@media (max-width: 768px) {
  .parallaxContainer {
    flex-direction: column;
  }

  .parallaxImage {
    height: 50vh;
  }

  .content {
    padding: 2rem;
  }
}

.aboutSection {
  padding: 4rem 0;
  background-color: map.get($theme, "antiflashWhite");

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }

  h2 {
    color: map.get($theme, "richBlack");
    margin-bottom: 2rem;
    text-align: center;
    margin-top: 2.5rem;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 50px;
      height: 4px;
      background-color: map.get($theme, "richBlack");
      margin: 0.5rem auto;
    }

    .companyName {
      color: map.get($theme, "yinmnRed");
      font-size: 2rem;
    }
  }

  p {
    font-size: 1.125rem;
    color: map.get($theme, "richBlack");
    line-height: 1.8;
    margin-bottom: 1.5rem;
    text-align: center;
  }
}

.testimonialsSection {
  padding: 4rem 0;
  background-color: #f7f9fc;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }

  h2 {
    font-size: 2rem;
    color: map.get($theme, "richBlack");
    margin-bottom: 2rem;
    text-align: center;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 50px;
      height: 4px;
      background-color: map.get($theme, "yinmnRed");
      margin: 1rem auto;
    }
  }

  .testimonials {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
  }

  .testimonialCard {
    background-color: map.get($theme, "whiteOnly");
    padding: 2rem;
    border-radius: 8px;
    box-shadow: map.get($theme, "shadow");
    max-width: 500px;
    width: 100%;
    transition: box-shadow 0.3s ease;

    &:hover {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }

    .message {
      font-size: 1.125rem;
      color: map.get($theme, "richBlack");
      line-height: 1.8;
      margin-bottom: 1.5rem;
    }

    .author {
      font-size: 1rem;
      color: map.get($theme, "richBlack");

      strong {
        display: block;
        font-size: 1.1rem;
        color: map.get($theme, "yinmnRed");
      }

      .company {
        font-style: italic;
        color: #888;
      }
    }
  }
}

@media (max-width: 768px) {
  .testimonials {
    flex-direction: column;
    align-items: center;
  }

  .aboutSection,
  .testimonialsSection {
    padding: 2rem 1rem;
  }
}
