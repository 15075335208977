@use "sass:map";
@import "../../assets/theme.scss";

@mixin center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.parallaxContainer {
  background-image: url("../../assets/img/pexels3.jpg");
  height: 450px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.contactPageContainer {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  background-color: map.get($theme, "antiflashWhite");
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.leftSection {
  flex: 1;
  padding: 1rem;

  h2 {
    font-size: 2rem;
    color: map.get($theme, "yinmnRed");
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .contactDetails {
    h3 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      color: map.get($theme, "yinmnRed");
    }

    .iconRow {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .iconCircle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border: 2px solid map.get($theme, "yinmnRed");
        border-radius: 50%;
        color: map.get($theme, "yinmnRed");
        margin-right: 15px;
        font-size: 24px;
        flex-shrink: 0;
      }

      .text {
        font-size: 15px;
        color: map.get($theme, "richBlack");
        line-height: 1.5;
        margin-top: 15px;
      }
    }
  }
}

.rightSection {
  flex: 1;
  padding: 1rem;
  background-color: map.get($theme, "whiteOnly");
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  h3 {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1rem;
    color: map.get($theme, "yinmnRed");
  }

  .contactForm {
    display: flex;
    flex-direction: column;

    .formGroup {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;

      label {
        font-weight: bold;
        margin-bottom: 0.5rem;
        color: map.get($theme, "richBlack");
      }
      input,
      textarea {
        padding: 0.75rem;
        border-radius: 5px;
        border: 1px solid #ccc;
        background-color: map.get($theme, "antiflashWhite");
        color: map.get($theme, "richBlack");
        font-size: 1rem;
      }

      textarea {
        resize: vertical;
        height: 100px;
      }
    }

    .submitButton {
      background-color: map.get($theme, "yinmnRed");
      color: map.get($theme, "whiteOnly");
      padding: 0.75rem;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      text-transform: uppercase;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: map.get($theme, "yinmRedAfter");
      }
    }
  }
}

.mapContainer {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: map.get($theme, "antiflashWhite");

  @media (max-width: 768px) {
    height: 300px;
  }
}

.mapIframe {
  width: 100%;
  height: 100%;
  border: none;
  display: block;
  position: relative;
}

.successMessage {
  color: green;
  text-align: left;
  font-weight: bold;
}

