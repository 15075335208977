@use "sass:map";
@import "../../assets/theme.scss";

.parallaxContainer {
  background-image: url("../../assets/img/pexels2.jpg");
  height: 450px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.quoteContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  background-color: map.get($theme, "antiflashWhite");
}

.headerText {
  position: relative;

  h1 {
    color: map.get($theme, "yinmnRed");
    font-size: 2rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 50px;
      height: 3px;
      background-color: map.get($theme, "richBlack");
      margin: 0.5rem auto 0 auto;
    }
  }

  p {
    color: map.get($theme, "richBlack");
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
}

.formContainer {
  max-width: 600px;
  width: 100%;
  padding: 1.5rem;
  background-color: map.get($theme, "whiteOnly");
  box-shadow: map.get($theme, "shadow");
  border-radius: 8px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputRow {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  .inputGroup {
    flex: 1;
    min-width: 48%;
    display: flex;
    flex-direction: column;

    label {
      font-weight: bold;
      color: map.get($theme, "richBlack");
      margin-bottom: 0.3rem;
    }

    input,
    select,
    textarea {
      padding: 0.6rem;
      font-size: 1rem;
      border-radius: 5px;
      border: 1px solid #ccc;
      background-color: map.get($theme, "antiflashWhite");
      color: map.get($theme, "richBlack");
      width: 100%;
    }

    textarea {
      resize: none;
      height: 100px;
    }
  }
}

.textarea {
  resize: none;
  width: 100%;
  height: 150px;
}

.fullWidth {
  width: 100%;
}

.submitButton {
  width: 100%;
  padding: 0.8rem;
  background-color: map.get($theme, "yinmnRed");
  color: map.get($theme, "whiteOnly");
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
  text-align: center;

  &:hover {
    background-color: map.get($theme, "yinmRedAfter");
  }
}

.error {
  color: map.get($theme, "yinmnRed");
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.mapContainer {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: map.get($theme, "antiflashWhite");

  @media (max-width: 768px) {
    height: 300px;
  }
}

.mapIframe {
  width: 100%;
  height: 100%;
  border: none;
  display: block;
  position: relative;
}
