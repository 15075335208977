.servicesContainer {
  padding: 4rem 2rem;
  background-color: #f9f9f9;
  text-align: center;
}

.heading {
  font-size: 2rem;
  color: #d3222d;
  margin-bottom: 2rem;
  font-weight: bold;
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    display: block;
    width: 50px;
    height: 3px;
    background-color: #333;
    margin: 0.5rem auto 0;
  }
}

p {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 2rem;
}

.serviceList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 2rem;
}

.serviceItem {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;

  &:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }

  .iconContainer {
    font-size: 2rem;
    color: #d3222d;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    color: #555;
  }
}

.accordionContainer {
  margin-top: 50px;
}

.accordion {
  max-width: 600px;
  margin: auto;
}

.accordionItem {
  background-color: white;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
}

.accordionHeader {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  cursor: pointer;
  background-color: #f9f9f9;
  border-radius: 8px;

  h3 {
    margin: 0;
  }

  span {
    font-size: 1.5rem;
    color: #d3222d;
  }
}

.accordionContent {
  padding: 1rem;
  background-color: white;
}

.mapContainer {
  width: 100%;
  height: 500px;
  margin-top: 2rem;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}

.parallaxContainer {
  background-image: url("../../assets/img/istock12.jpg");
  height: 450px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
